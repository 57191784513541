<template>
  <div class="container mb-3">
    <div class="xnpanel panel-success p-3">
      <div class="panel-body">
        <h6 class="panel-heading">充电桩提示音测试与设置</h6>
        <b-form-group>
          <label for="rating0" class="mt-2">声响次数：{{beeptimes}}</label>
          <b-form-rating id="rating0" v-model="beeptimes" stars="9" variant="danger" @change="saveme"></b-form-rating>
          <label for="rating1" class="mt-2">{{beephints[beepstyle-1]}}</label>
          <b-form-rating id="rating1" v-model="beepstyle" stars="8" variant="info" @change="saveme"></b-form-rating>		  
       </b-form-group>
       <b-form-group>
          <b-button variant="success" class="mt-2" block @click="beepnow" :disabled="loading">发到充电桩测试</b-button>
          <b-button class="btn-block mt-2" :class="workingid==0?'shadow-lg':''" variant="outline-success" @click="setv(0)">
              充电桩开机成功:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[0]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[1]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==1?'shadow-lg':''" variant="outline-success" @click="setv(1)">
              汽车充电枪插入:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[2]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[3]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==2?'shadow-lg':''" variant="outline-success" @click="setv(2)">
              汽车充电枪拔出:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[4]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[5]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==3?'shadow-lg':''" variant="outline-success" @click="setv(3)">
              充电启动成功音:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[6]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[7]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==4?'shadow-lg':''" variant="outline-success" @click="setv(4)">
              急停按钮按下音:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[8]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[9]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==5?'shadow-lg':''" variant="outline-success" @click="setv(5)">
              急停弹出恢复音:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[10]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[11]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==6?'shadow-lg':''" variant="outline-success" @click="setv(6)">
              单车直接拔插头:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[12]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[13]}}
          </b-button>
          <b-button class="btn-block mt-2" :class="workingid==7?'shadow-lg':''" variant="outline-success" @click="setv(7)">
              单车主动停充音:
              <b-icon icon="star-fill" variant="danger"></b-icon>{{savedstyles[14]}}
              <b-icon icon="star-fill" variant="info"></b-icon>{{savedstyles[15]}}
          </b-button>
          <b-button class="mt-2 btn-block" variant="primary" @click="savenow()">保存以上设置</b-button>
          <b-button class="mt-2 btn-block" variant="danger" @click="reboot" :disabled="loading">设备重启</b-button>
      </b-form-group>
      </div>
      <div class="panel-footer loginmsg">
        {{errormsg}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'reset',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        loading: false,
        beeptimes:1,
        beepstyle:1,
        workingid:-1,
        savedstyles:[6,3,2,1,2,1,3,1,1,1,1,6,3,1,3,1],
        beephints:['响停时长:0.13秒(一秒8响,推荐)','响停时长:0.25秒(一秒4响)','响停时长:0.5秒','响停时长:1秒(响半秒)','声音时间:1秒(停1秒)','响2秒停2秒,共4秒','响停时长:6秒','响停时长:8秒(单响4秒)'],
        mac: this.$route.params.mac
      };
    },
    methods: {
      fetchData(){
        let token = localStorage.getItem('token');
        let theapi = '/getbeepstyles?tm='+new Date().getTime()+'&token='+token;
        this.axios.get(theapi).then( (resp) => {
            this.savedstyles = resp.data.beepstyles;
        });
      },
      setv(id) {
          this.workingid = id;
          this.beeptimes = this.savedstyles[id*2];
          this.beepstyle = this.savedstyles[id*2+1];
      },
      saveme(){
          if (this.workingid>-1) {
              this.savedstyles[this.workingid*2] = this.beeptimes;
              this.savedstyles[this.workingid*2+1] = this.beepstyle;
          }
      },
      beepnow() {
        this.errormsg = '';
        if (this.beeptimes < 1) {
          this.errormsg = '声响次数不能为0';
        } else if (this.beeptimes > 9) {
          this.errormsg = '声响次数太大';
        } else if (this.beestyle > 8) {
          this.errormsg = '声响类型不对';
        } else {
          this.loading = true;
          let theapi = '/beepnow?tm=' + new Date().getTime() + '&mac=' + this.mac + '&times=' + this.beeptimes + '&style=' + (this.beepstyle-1);
          this.axios.get(theapi).then( () => {
            this.loading = false;
            this.errormsg = '';
          });
        }
      },
      savenow(){
        let token = localStorage.getItem('token');
        let savevalue = '';
        for (let i=0;i<this.savedstyles.length;i++) {
            if (i%2==0) {
                savevalue = savevalue + '' + this.savedstyles[i];
            } else {
                savevalue = savevalue + '' + (this.savedstyles[i]-1);
            }
        }
        let theapi = '/savebeepstyles?tm='+new Date().getTime()+'&token='+token+'&saved='+savevalue;
        this.axios.get(theapi).then( () => {
            this.fetchData();
        });
      },
      reboot() {
        this.loading = true;
        let token = localStorage.getItem('token');
        let theapi = '/docmd?tm='+new Date().getTime()+'&token='+token+'&mac='+this.mac+'&cmd=1';
        this.axios.get(theapi).then();
      },
    }
  }
</script>
